<template>
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Body-->
    <div class="card-body d-flex flex-column">
      <div class="flex-grow-1 pb-5">
        <!--begin::Info-->
        <h1 style="text-transform: uppercase">
          {{ $t("OVERVIEW.PAGETITLE") }}
        </h1>
        <div class="d-flex align-items-center mb-6">
          <span class="text-muted font-weight-bold font-size-lg flex-grow-1">
            {{ $t("OVERVIEW.Description") }}</span
          >
          <!--          <div class="symbol symbol-50">
            <span class="symbol-label bg-light-light">
              <img
                src="media/svg/icons/Home/Timer.svg"
                class="h-50 align-self-center"
                alt=""
              />
            </span>
          </div>-->
        </div>
        <!--end::Info-->

        <div class="vld-parent">
          <loading
            :active.sync="isLoading"
            :can-cancel="true"
            :is-full-page="true"
          ></loading>
        </div>

        <v-app>
          <v-container>
            <v-row v-if="currentUser.admin === 1" style="margin-top: 25px;">
              <v-col cols="12" class="d-flex justify-end text-center">
                <v-btn
                  class="primary custom_button"
                  large
                  height="50"
                  style="margin-right: 15px;"
                  @click="toInvoice(document_type_id.DOCUMENT_INVOICE)"
                >
                  <v-icon left>mdi-plus</v-icon>
                  {{ $t("OVERVIEW.Button_NewInvoice") }}
                </v-btn>
                <v-btn
                  class="primary custom_button"
                  large
                  height="50"
                  style="margin-right: 15px;"
                  @click="toInvoice(document_type_id.DOCUMENT_CREDIT)"
                >
                  <v-icon left>mdi-plus</v-icon>
                  {{ $t("OVERVIEW.Button_CreditNote") }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="documents"
                  :items-per-page="30"
                  sort-by="invoice_number"
                  sort-desc
                  class="table1"
                  :loading="loadingvariable"
                  loading-text="Bitte warten... Dokumente werden geladen"
                >
                  <template v-slot:footer.page-text="items">
                    {{ items.pageStart }} von {{ items.itemsLength }}
                  </template>
                  <template v-slot:item.documents_type_id="{ item }">
                    <v-chip
                      style="font-size: 12px"
                      color="secondary"
                      :text-color="
                        item.documents_type_id !== 3 ? 'primary' : 'red'
                      "
                    >
                      {{ getDocumentType(item.documents_type_id) }}
                    </v-chip>
                  </template>
                  <template v-slot:item.status="{ item }">
                    {{ $t("OVERVIEW." + item.status) }}
                  </template>
                  <template v-slot:item.auto_generated="{ item }">
                    <div v-if="item.auto_generated === 1">
                      <v-chip
                          style="font-size: 12px"
                          color="secondary"
                          :text-color="
                        item.auto_generated !== 0 ? 'primary' : 'black'
                      "
                      >
                        {{ item.auto_generated === 1 ? "JA" : "NEIN" }}
                      </v-chip>
                    </div>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-tooltip v-if="currentUser.admin === 1" top attach="" dense color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          class="mr-2"
                          @click="toInvoiceWithHash(item)"
                          text="test"
                          style="font-size: 20px;"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-magnify
                        </v-icon>
                      </template>
                      <span>Öffne Dokument</span>
                    </v-tooltip>
                    <v-tooltip v-if="currentUser.admin === 1" top attach="" dense color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-if="
                            item.status === document_status_types.STATUS_DRAFT
                          "
                          small
                          :disabled="item.edited !== 0"
                          class="mr-2"
                          @click="deleteDocument(item)"
                          text="test"
                          style="font-size: 20px;"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>Lösche Dokument</span>
                    </v-tooltip>
                    <v-tooltip top attach="" dense color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-if="
                            item.status !== document_status_types.STATUS_DRAFT
                          "
                          small
                          class="mr-2"
                          @click="getPdf(item)"
                          style="font-size: 18px;"
                          color="brown"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-file-pdf
                        </v-icon>
                      </template>
                      <span>PDF Download</span>
                    </v-tooltip>
                    <v-tooltip v-if="currentUser.admin === 1" top attach="" dense color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-if="
                            item.status !==
                              document_status_types.STATUS_DRAFT &&
                              !item.reference_number
                          "
                          small
                          class="mr-2"
                          @click="createCancellation(item.unique_hash)"
                          style="font-size: 18px;"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-file-document-box-remove
                        </v-icon>
                      </template>
                      <span>Rechnung stornieren</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:no-data>
                    <div>Es sind keine Dokumente vorhanden</div>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-app>
      </div>
    </div>
  </div>
</template>
<style>
.v-data-footer__select {
  display: none !important;
}

.custom_button.theme--light.v-btn.v-btn--disabled {
  background-color: lightgray !important;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import { LOGOUT } from "@/core/services/store/auth.module";
import DocumentType from "@/stub/doctype";
import DocumentStatusTypes from "@/stub/statustypes";
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import VTooltip from "v-tooltip";
import Vue from "vue";
import {getUserProfile} from "@/core/services/store/auth.module";

Vue.use(VTooltip);

export default {
  name: "overview",
  components: { Loading },
  data: function() {
    return {
      pageTitle: "documents Overview",
      selectedCompany: null,
      loadingvariable: false,
      currentUser: null,
      headers: [
        { text: this.$t("OVERVIEW.Type"), value: "documents_type_id" },
        { text: this.$t("OVERVIEW.Number"), value: "invoice_number" },
        { text: this.$t("OVERVIEW.Date"), value: "invoice_date" },
        { text: this.$t("OVERVIEW.Client"), value: "client.CompanyName" },
        { text: this.$t("OVERVIEW.Status"), value: "status" },
        { text: this.$t("OVERVIEW.AUTO_GENERATED"), value: "auto_generated" },
        { text: this.$t("OVERVIEW.Creater"), value: "user.lastname" },
        { text: this.$t("OVERVIEW.Options"), value: "actions", sortable: false }
      ],
      documents: {},
      document_type_id: {
        ...DocumentType
      },
      document_status_types: {
        ...DocumentStatusTypes
      },
      copyInvoiceTemp: {},
      openCopyInvoiceDialog: false,
      openCopyInvoiceToClientDialog: false,
      isLoading: false
    };
  },
  created() {
    this.documents = [];
    this.loadingvariable = true;

    ApiService.query("/companies")
      .then(({ data }) => {
        this.loadingvariable = false;
        this.selectedCompany = data.companies[0];

        if (this.$route.query.companyid) {
          data.selectedCompany.forEach(entry => {
            if (this.$route.query.companyid === entry.CompanyId.toString()) {
              this.selectedCompany = entry;
            }
          });
        } else this.getLastInvoices();
      })
      .catch(({ response }) => {
        if (response.status === 401) {
          if (response.status === 401) {
            this.loadingvariable = false;
            this.$store
              .dispatch(LOGOUT)
              .then(() => this.$router.push({ name: "login" }));
          }
        }
      });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Overview" }]);

    this.currentUser = getUserProfile();
    this.currentUser = JSON.parse(this.currentUser);
  },
  watch: {
    /*selectedCompany(company) {
      this.selectedCompanyCopy = Object.assign({}, this.selectedCompany);

      if (company) {
        this.loadingvariable = true;
        this.getInvoices(company.CompanyId);
      }
    }*/
  },
  methods: {
    getDocumentType(documents_type_id) {
      switch (documents_type_id) {
        case 1:
          return "RECHNUNG";
        case 2:
          return "GUTSCHRIFT";
        case 3:
          return "STORNIERUNG";
        default:
          return "-";
      }
    },
    createCancellation(unique_hash) {
      Swal.fire({
        title: "Stornierung erstellen",
        text: "Möchten Sie diese Rechnung wirklich stornieren?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ja",
        cancelButtonText: "Abbrechen"
      }).then(invoice => {
        if (invoice.value) {
          this.isLoading = true;
          ApiService.post("invoice/cancellation", {
            unique_hash: unique_hash
          })
            .then(() => {
              location.reload();
            })
            .catch(({ response }) => {
              if (response.status === 400) {
                Swal.fire({
                  title: "Cancellation",
                  text: response.data.message,
                  icon: "error",
                  confirmButtonText: "Ok"
                });
              }
              if (response.status === 401) {
                this.$store
                  .dispatch(LOGOUT)
                  .then(() => this.$router.push({ name: "login" }));
              }
              this.isLoading = false;
            });
        }
      });
    },
    getPdf(item) {
      this.isLoading = true;
      ApiService.getFile("/invoice/pdf", item.unique_hash)
        .then(response => {
          this.isLoading = false;
          const blobUrl = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          const link = document.createElement("a");
          link.href = blobUrl;
          link.setAttribute("download", item.invoice_number);
          link.click();
          link.remove();
          URL.revokeObjectURL(blobUrl);
        })
        .catch(({ response }) => {
          if (response.status === 401) {
            if (response.status === 401) {
              this.$store
                .dispatch(LOGOUT)
                .then(() => this.$router.push({ name: "login" }));
            }
          }
        });
    },
    toInvoice(document_type_id) {
      //this.$router.push("/dashboard/schedules");

      this.$router.push({
        name: "invoice",
        query: { type: document_type_id },
        params: { company: this.selectedCompany },
        pathToRegexpOptions: { strict: true }
      });

      history.pushState(
        {},
        null,
        "#" + this.$route.path + "?companyid=" + this.selectedCompany
      );
    },

    deleteDocument(item) {
      Swal.fire({
        title: "Dokument löschen",
        text: "Möchten Sie dieses Dokument wirklich löschen?",
        icon: "warning",
        timerProgressBar: true,
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        confirmButtonText: "Löschen",
        cancelButtonText: "Abbrechen"
      }).then(result => {
        if (result.value) {
          this.isLoading = true;
          ApiService.post("/invoice/delete/" + item.unique_hash)
            .then(({ data }) => {
              this.isLoading = false;
              let index = this.documents.indexOf(item);
              this.documents.splice(index, 1);
              Swal.fire("", data.message, "success");
            })
            .catch(({ response }) => {
              if (response.status === 403) {
                Swal.fire("", response.data.message, "error");
              }
              if (response.status === 401) {
                if (response.status === 401) {
                  this.$store
                    .dispatch(LOGOUT)
                    .then(() => this.$router.push({ name: "login" }));
                }
              }
              this.isLoading = false;
            });
        }
      });
    },
    toInvoiceWithHash(item) {
      //this.$router.push("/dashboard/schedules");
      this.$router.push({
        name: item.unique_hash ? "invoice-hash" : "invoice",
        params: { hash: item.unique_hash },
        pathToRegexpOptions: { strict: true }
      });

      history.pushState(
        {},
        null,
        this.$route.path + "?companyid=" + this.selectedCompany.CompanyId
      );
    },
    getInvoices(companyid) {
      ApiService.query("/overview/filtered", companyid)
        .then(({ data }) => {
          this.documents = data.invoices;
          this.loadingvariable = false;
        })
        .catch(({ response }) => {
          if (response.status === 401) {
            if (response.status === 401) {
              this.$store
                .dispatch(LOGOUT)
                .then(() => this.$router.push({ name: "login" }));
            }
          }
          this.loadingvariable = false;
        });
    },
    getLastInvoices() {
      this.loadingvariable = true;
      ApiService.query("/overview")
        .then(({ data }) => {
          this.documents = data.invoices;
          this.loadingvariable = false;
        })
        .catch(({ response }) => {
          if (response.status === 401) {
            if (response.status === 401) {
              this.loadingvariable = false;
              this.$store
                .dispatch(LOGOUT)
                .then(() => this.$router.push({ name: "login" }));
            }
          }
        });
    },
    closeCopyInvoices() {
      this.openCopyInvoiceDialog = false;
    },
    getHeaders(headingText) {
      return [
        {
          text: "Dynamic heading no. " + headingText,
          align: "left",
          sortable: false,
          value: "name"
        }
      ];
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  }
};
</script>
